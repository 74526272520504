import "firebase/auth";
import firebase from "../../Firebase";
import React, { useState, useEffect } from "react";
import DataBlock from "./verified_details";
import { Card, Form, Button } from "react-bootstrap";
import { render } from "@testing-library/react";
import UnVerified from "./unverified_details";

const ref = firebase.firestore().collection("UserDetails");
console.log(ref);

function District_Unverified() {
  const [user, setUser] = useState({
    assembly: "",
  });

  let assembly, value;

  const handleInputs = (e) => {
    console.log(e);
    assembly = e.target.name;
    value = e.target.value;
    setUser({ ...user, [assembly]: value });
    // console.log(value)
  };
  
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);

  function getData() {
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        if (1) {
          items.push(doc.data());
        }
      });
      setData(items);
      setLoader(false);
    });
  }

  useEffect(() => {
    getData();
    console.log('data',data.length);
  }, []);

  const handleSubmit = (e) => {
    console.log(user.assembly);
    // alert("hello")
    document.getElementById("assembly_form").style.display = "none";
    render(
      <div>
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">District Members</h6>
        </div>
          <div class="table-responsive">
            <table
              class="table table-bordered"
              id="datatable1"
              width="100%"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th>Mem Uid</th>
                  <th>Name</th>
                  <th>State</th>
                  <th>District</th>
                </tr>
              </thead>

              {loader === false &&
                data.map((dev) => (
                  <UnVerified dev={dev} assemblyf={user.assembly} />
                ))}
            </table>
        </div>
      </div>
    );
  };

  if (1) {
    return (
      <div id="assembly_form">
        <Card.Body>
          <h2 className="text-center mb-4">Login</h2>
        </Card.Body>

        <Form>
          <Form.Group className="mb-3">
            <Form.Label>District</Form.Label>
            <Form.Control
              value={user.assembly}
              onChange={handleInputs}
              type="text"
              name="assembly"
              id="assembly"
              placeholder="Enter District"
            />
          </Form.Group>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Form>
      </div>
    );
  } else {
    return (
      <div>
        {loader === false &&
          data.map((dev) => <UnVerified dev={dev} assemblyf={user.assembly} />)}
      </div>
    );
  }
}

export default District_Unverified;
