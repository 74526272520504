import React from 'react'
import Tabs from '../Tabs/tabs';
import './tab.css'
import { Card, Form, Button } from "react-bootstrap";
import S_D_Assembly_verified from './s_d_a_v';
import S_D_Assembly_unverified from './s_d_a_uv';

function S_D_A_mem({info}) {
    console.log("this",info)
    // console.log(person.mem_consti)
  return (
    <div>
        <Tabs>
        <div label="Verified">
        <S_D_Assembly_verified info={info.mem_state} des={info.designation}/>
        </div>
        <div label="Unverified">
        <S_D_Assembly_unverified info={info.mem_state} des={info.designation}/>
        </div>
      </Tabs>
    </div>
  )
}

export default S_D_A_mem;