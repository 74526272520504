import React from 'react'
import { useHistory,Navigate, useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from "react";
import "firebase/auth";
import firebase from "../../Firebase";
import { Card, Form, Button } from "react-bootstrap";
import Tabs from '../Tabs/tabs';
import bcrypt from "bcryptjs/dist/bcrypt";



// let data=[];

function S_v_profile() {

  
const ref = firebase.firestore().collection("UserDetails");
  // let location = useLocation();
  // let v=location.state.district;

// const [ loading, setLoading ] = useState(false);

let location=useLocation();
console.log("jii",location)

const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);


function getData() {
  ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
          if (doc.data().mobile_no==phone) {
              items.push(doc.data());
          }
      });
      setData(items);
      setLoader(false);
  });
}



  let {phone}=useParams();
  useEffect(() => {
    getData();
    console.log(data);
    console.log("Assembly");
  }, []);

  console.log(phone,data,loader);

  return (
    <div>
      {loader ? <F_load/> : <F_ver mem={data[0]}/>}
    </div>

  )

  function F_load(){
    return <div>
      {/* hii */}
    </div>
  }

  function F_ver({mem}){

    const [user, setUser] = useState({
        pass:''
    });

    let assembly, value;

    const handleInputs = (e) => {
        assembly = e.target.name;
        value = e.target.value;
        setUser({ ...user, [assembly]: value });
      };


  const handleSubmit = (e) => {
    // const uploadFiles=()=>{
    firebase.firestore().collection("UserDetails").doc(phone).update({is_verified:"No"})
              .then(() => {
                  console.log("up")
                  alert("User Unverified")
              })
              .catch((err) => {
                  alert(err);
                  console.error(err);
              });
  }

  const mhandlepass=(e)=>{
      if(document.getElementById('mpass').style.display=='none'){
        document.getElementById('mpass').style.display='block'
      }
      else{
        document.getElementById('mpass').style.display='none'
      }
  }

  const handlepass=(e)=>{
      console.log(user.pass)
      firebase
      .firestore()
      .collection("UserDetails")
      .doc(phone)
      .update({
        pass:bcrypt.hashSync(user.pass, bcrypt.genSaltSync())
      })
      .then(() => {
        console.log("up");
        alert("Password Updated");
      })
      .catch((err) => {
        alert(err);
        console.error(err);
      });
  }
  
    
    return <div>
    <Tabs>
    <div label="Personal">
    <Form>
            <Form.Group className="mb-3">
                <Form.Label><b>Full Name</b></Form.Label>
                <Form.Control
                    value={data[0].full_name}
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder={data[0].full_name}
                    disabled
                />
                <br/>
                <Form.Label><b>Father/Husband Name</b></Form.Label>
                <Form.Control
                    value={data[0].father_husband}
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder={data[0].father_husband}
                    disabled
                />
                <br/>
                <Form.Label><b>Nickname</b></Form.Label>
                <Form.Control
                    value={data[0].nickname}
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder={data[0].nickname}
                    disabled
                />
                <br/>
                <Form.Label><b>Email</b></Form.Label>
                <Form.Control
                    value={data[0].email_id}
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder={data[0].email_id}
                    disabled
                />
                <br/>
                <Form.Label><b>Gender</b></Form.Label>
                <Form.Control
                    value={data[0].gender}
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder={data[0].gender}
                    disabled
                />
                <br/>
                <Form.Label><b>Date of Birth</b></Form.Label>
                <Form.Control
                    value={data[0].dob}
                    type="date"
                    name="phone"
                    id="phone"
                    placeholder={data[0].dob}
                    disabled
                />
            </Form.Group>
            <Button variant="danger" onClick={handleSubmit}>
                Unverify
            </Button>
            <br/>
            <br/>
            <Button variant="warning" onClick={mhandlepass}>
                Reset Password
            </Button>
            <div id="mpass" style={{display:'none'}}>
            <br/>
                    <Form.Label><b>Enter Password</b></Form.Label>
                    <Form.Control
                    value={user.pass}
                        onChange={handleInputs}
                        type="text"
                        name="pass"
                        id="pass"
                        placeholder='Enter New Password'
                    />
                    <br/>
                     <Button variant="success" onClick={handlepass}>
                Confirm
            </Button>
            </div>
        </Form>
    </div>
    <div label="Communication">
    <Form>
            <Form.Group className="mb-3">
                <Form.Label><b>Country</b></Form.Label>
                <Form.Control
                    value={data[0].country}
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder={data[0].country}
                    disabled
                />
                <br/>
                <Form.Label><b>Address</b></Form.Label>
                <Form.Control
                    value={data[0].mem_add}
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder={data[0].mem_add}
                    disabled
                />
                <br/>
                <Form.Label><b>Pincode</b></Form.Label>
                <Form.Control
                    value={data[0].pincode}
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder={data[0].pincode}
                    disabled
                />
                <br/>
                <Form.Label><b>Post-Office</b></Form.Label>
                <Form.Control
                    value={data[0].post_office}
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder={data[0].post_office}
                    disabled
                />
                <br/>
                <Form.Label><b>State</b></Form.Label>
                <Form.Control
                    value={data[0].mem_state}
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder={data[0].mem_state}
                    disabled
                />
                <br/>
                <Form.Label><b>District</b></Form.Label>
                <Form.Control
                    value={data[0].mem_district}
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder={data[0].mem_district}
                    disabled
                />
                <br/>
                <Form.Label><b>Constituency</b></Form.Label>
                <Form.Control
                    value={data[0].mem_consti}
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder={data[0].mem_consti}
                    disabled
                />
                <br/>
                <Form.Label><b>Booth No.</b></Form.Label>
                <Form.Control
                    value={data[0].booth_no}
                    type="text"
                    name="text"
                    id="phone"
                    placeholder={data[0].booth_no}
                    disabled
                />
            </Form.Group>
            <Button variant="danger" onClick={handleSubmit}>
                Unverify
            </Button>
            <br/>
            <br/>
            <Button variant="warning" onClick={mhandlepass}>
                Reset Password
            </Button>
            <div id="mpass" style={{display:'none'}}>
            <br/>
                    <Form.Label><b>Enter Password</b></Form.Label>
                    <Form.Control
                    value={user.pass}
                        onChange={handleInputs}
                        type="text"
                        name="pass"
                        id="pass"
                        placeholder='Enter New Password'
                    />
                    <br/>
                     <Button variant="success" onClick={handlepass}>
                Confirm
            </Button>
            </div>
        </Form>
    </div>
    <div label="Identification">
    <Form>
            <Form.Group className="mb-3">
                <Form.Label><b>Voter Id</b></Form.Label>
                <Form.Control
                    value={data[0].voter_id}
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder={data[0].voter_id}
                    disabled
                />
                <br/>
                <Form.Label><b>AADHAR No.</b></Form.Label>
                <Form.Control
                    value={data[0].aadhar_no}
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder={data[0].aadhar_no}
                    disabled
                />
                <br/>
                <Form.Label><b>PAN No.</b></Form.Label>
                <Form.Control
                    value={data[0].pan_no}
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder={data[0].pan_no}
                    disabled
                />
                               </Form.Group>
                               
            <Button variant="danger" onClick={handleSubmit}>
                Unverify
            </Button>
            <br/>
            <br/>
            <Button variant="warning" onClick={mhandlepass}>
                Reset Password
            </Button>
            <div id="mpass" style={{display:'none'}}>
            <br/>
                    <Form.Label><b>Enter Password</b></Form.Label>
                    <Form.Control
                    value={user.pass}
                        onChange={handleInputs}
                        type="text"
                        name="pass"
                        id="pass"
                        placeholder='Enter New Password'
                    />
                    <br/>
                     <Button variant="success" onClick={handlepass}>
                Confirm
            </Button>
            </div>
        </Form>
    </div>
  </Tabs>
</div>
  }
}

export default S_v_profile