import React from 'react'
import Tabs from '../Tabs/tabs';
import './tab.css'
import { Card, Form, Button } from "react-bootstrap";

function District_profile({person}) {
    console.log("this",person)
    // console.log(person.mem_consti)
  return (
    <div>
        <Tabs>
        <div label="Personal">
        <Form>
                <Form.Group className="mb-3">
                    <Form.Label><b>Full Name</b></Form.Label>
                    <Form.Control
                        value={person.full_name}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder={person.full_name}
                    />
                    <br/>
                    <Form.Label><b>Father/Husband Name</b></Form.Label>
                    <Form.Control
                        value={person.father_husband}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder={person.father_husband}
                    />
                    <br/>
                    <Form.Label><b>Nickname</b></Form.Label>
                    <Form.Control
                        value={person.nickname}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder={person.nickname}
                    />
                    <br/>
                    <Form.Label><b>Email</b></Form.Label>
                    <Form.Control
                        value={person.email_id}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder={person.email_id}
                    />
                    <br/>
                    <Form.Label><b>Gender</b></Form.Label>
                    <Form.Control
                        value={person.gender}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder={person.gender}
                    />
                    <br/>
                    <Form.Label><b>Date of Birth</b></Form.Label>
                    <Form.Control
                        value={person.dob}
                        type="date"
                        name="phone"
                        id="phone"
                        placeholder={person.dob}
                    />
                </Form.Group>
                <Button variant="warning">
                    Submit
                </Button>
            </Form>
        </div>
        <div label="Communication">
        <Form>
                <Form.Group className="mb-3">
                    <Form.Label><b>Country</b></Form.Label>
                    <Form.Control
                        value={person.country}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder={person.country}
                        disabled
                    />
                    <br/>
                    <Form.Label><b>Address</b></Form.Label>
                    <Form.Control
                        value={person.mem_add}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder={person.mem_add}
                        disabled
                    />
                    <br/>
                    <Form.Label><b>Pincode</b></Form.Label>
                    <Form.Control
                        value={person.pincode}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder={person.pincode}
                        disabled
                    />
                    <br/>
                    <Form.Label><b>Post-Office</b></Form.Label>
                    <Form.Control
                        value={person.post_office}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder={person.post_office}
                        disabled
                    />
                    <br/>
                    <Form.Label><b>State</b></Form.Label>
                    <Form.Control
                        value={person.mem_state}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder={person.mem_state}
                        disabled
                    />
                    <br/>
                    <Form.Label><b>District</b></Form.Label>
                    <Form.Control
                        value={person.mem_district}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder={person.mem_district}
                        disabled
                    />
                    <br/>
                    <Form.Label><b>Constituency</b></Form.Label>
                    <Form.Control
                        value={person.mem_consti}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder={person.mem_consti}
                        disabled
                    />
                    <br/>
                    <Form.Label><b>Booth No.</b></Form.Label>
                    <Form.Control
                        value={person.booth_no}
                        type="text"
                        name="text"
                        id="phone"
                        placeholder={person.booth_no}
                        disabled
                    />
                </Form.Group>
                <Button variant="warning">
                    Submit
                </Button>
            </Form>
        </div>
        <div label="Identification">
        <Form>
                <Form.Group className="mb-3">
                    <Form.Label><b>Voter Id</b></Form.Label>
                    <Form.Control
                        value={person.voter_id}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder={person.voter_id}
                        disabled
                    />
                    <br/>
                    <Form.Label><b>AADHAR No.</b></Form.Label>
                    <Form.Control
                        value={person.aadhar_no}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder={person.aadhar_no}
                    />
                    <br/>
                    <Form.Label><b>PAN No.</b></Form.Label>
                    <Form.Control
                        value={person.pan_no}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder={person.pan_no}
                    />
                                   </Form.Group>
                <Button variant="warning">
                    Submit
                </Button>
            </Form>
        </div>
      </Tabs>
    </div>
  )
}

export default District_profile;