import React from "react";
import { useHistory, Navigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import "firebase/auth";
import firebase from "../../Firebase";
import { Card, Form, Button } from "react-bootstrap";

// let data=[];

function Promote({ mob,det }) {
  const ref = firebase.firestore().collection("UserDetails");
  // let location = useLocation();
  // let v=location.state.district;

  // const [ loading, setLoading ] = useState(false);

  let location = useLocation();
  console.log("jii", location);

  const [data, setData] = useState([]);
  const [pa, setPa] = useState([]);
  const [loader, setLoader] = useState(true);

  function getData() {
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        if (doc.data().mobile_no == phone) {
          items.push(doc.data());
        }
      });
      setData(items);
      setLoader(false);
    });
  }

  // const storageRef=firebase.storage().ref;
  var storage = firebase.storage();
  let assembly, value, voter_slip_url;

  let { phone } = useParams();
  useEffect(() => {
    getData();
    console.log(data);
    console.log("Assembly");
  }, []);

  console.log(phone, data, loader);

  return <div>{loader ? <F_load /> : <F_ver mem={data[0]} />}</div>;

  function F_load() {
    return <div>{/* hii */}</div>;
  }

  function F_ver({ mem }) {
    const [user, setUser] = useState({
      file: "",
      desig: "Select a designation",
      commitee: "Select a commitee",
      desig_: "",
      by: mob,
    });

    console.log("mem", mem);
    const handleInputs = (e) => {
      assembly = e.target.name;
      value = e.target.value;
      setUser({ ...user, [assembly]: value });
      console.log(user);
      if (e.target.name == "desig") {
        if (e.target.value == "other") {
          document.getElementById("desig").style.display = "block";
        } else {
          document.getElementById("desig").style.display = "none";
        }
      }
    };

    const onFileChange = (e) => {
      setUser({ ...user, ["file"]: e.target.files[0] });
    };

    const handleSubmit = (e) => {
      console.log("hiii", user.file);
      let file = user.file;
      let p = 0;
      if (
        user.desig != "Select a designation" &&
        user.commitee != "Select a commitee"
      ) {
        if (!file) {
          user.file = "";
          if (user.desig != "other") {
            // if (user.commitee == "Assembly") {
            //   ref.onSnapshot((querySnapshot) => {
            //     const items = [];
            //     let cnt=0;
            //     querySnapshot.forEach((doc) => {
            //       if (doc.data().designation == user.desig && doc.data().mem_consti==det.mem_consti) {
            //         console.log('kk')
            //         cnt++;
            //       }
            //     });
                
            //     setPa(cnt);
            //   });
            // }
            // console.log(pa);
            // if (pa !=0) {
              firebase
                .firestore()
                .collection("UserDetails")
                .doc(phone)
                .update({
                  user_role: "admin",
                  commitee: user.commitee,
                  designation: user.commitee + " " + user.desig,
                  join_letter: user.file,
                  promoted_by: user.by,
                })
                .then(() => {
                  console.log("up");
                  alert("User has been promoted");
                })
                .catch((err) => {
                  alert(err);
                  console.error(err);
                });
            // } else {
            //   alert("This deignation is not vacant");
            //   setPa(0)
            // }
          } else {
            firebase
              .firestore()
              .collection("UserDetails")
              .doc(phone)
              .update({
                user_role: "admin",
                commitee: user.commitee,
                designation: user.commitee + " " + user.desig_,
                join_letter: user.file,
                promoted_by: user.by,
              })
              .then(() => {
                console.log("up");
                alert("User has been promoted");
              })
              .catch((err) => {
                alert(err);
                console.error(err);
              });
          }
        } else {
          storage
            .ref(`/VoterSlip/${phone}`)
            .put(file)
            .on("state_changed", (err) => console.log(err));
          storage
            .ref(`/VoterSlip/${phone}`)
            .getDownloadURL()
            .then(function (url) {
              voter_slip_url = url;
              console.log(voter_slip_url);
              if (user.desig != "other") {
                firebase
                  .firestore()
                  .collection("UserDetails")
                  .doc(phone)
                  .update({
                    user_role: "admin",
                    commitee: user.commitee,
                    designation: user.commitee + " " + user.desig,
                    join_letter: voter_slip_url,
                    promoted_by: user.by,
                  })
                  .then(() => {
                    console.log("up");
                    alert("User has been promoted");
                  })
                  .catch((err) => {
                    alert(err);
                    console.error(err);
                  });
              } else {
                firebase
                  .firestore()
                  .collection("UserDetails")
                  .doc(phone)
                  .update({
                    user_role: "admin",
                    commitee: user.commitee,
                    designation: user.commitee + " " + user.desig_,
                    join_letter: voter_slip_url,
                    promoted_by: user.by,
                  })
                  .then(() => {
                    console.log("up");
                    alert("User has been promoted");
                  })
                  .catch((err) => {
                    alert(err);
                    console.error(err);
                  });
              }
            });
        }
      } else {
        alert("Please enter required fields");
      }
      console.log("voter_slip_url", voter_slip_url);
      console.log(user);
      e.preventDefault();
    };

    return (
      <div>
        <Form>
          {/* {data[0].full_name + data[0].mobile_no} */}
          <Form.Group>
            <Form.Label>
              <b>Member Name and Voter Id</b>
            </Form.Label>
            <Form.Control
              value={data[0].full_name + " (" + data[0].voter_id + ")"}
              type="text"
              id="name"
              name="name"
              disabled
            />
            <br />
            <Form.Label>
              <b>Voter Slip</b>
            </Form.Label>
            <Form.Control
              type="file"
              onChange={onFileChange}
              // required
            />
            <br />
            <Form.Select
              name="commitee"
              value={user.commitee}
              onChange={handleInputs}
            >
              <option value="Select Commitee">Select committee</option>
              <option value="Assembly">Assembly</option>
              <option value="Booth">Booth</option>
            </Form.Select>
            <br />
            <Form.Select
              name="desig"
              value={user.desig}
              onChange={handleInputs}
            >
              <option value="Select a designation">Select a designation</option>
              <option value="President">President</option>
              <option value="Vice President">Vice President</option>
              <option value="General Secretary">General Secretary</option>
              <option value="Youth President">Youth President</option>
              <option value="Mahila President">Mahila President</option>
              <option value="Media President">Media President</option>
              <option value="IT President">IT President</option>
              <option value="Student President">Student President</option>
              <option value="HelpLine">HelpLine</option>
              <option value="Secretary">Secretary</option>
              <option value="other">Other</option>
            </Form.Select>
            <br />

            <Form.Control
              id="desig"
              style={{ display: "none" }}
              // value={user.desig}
              onChange={handleInputs}
              type="text"
              name="desig_"
              placeholder="Enter designation"
            />
            <br />
          </Form.Group>
          <Button variant="success" onClick={handleSubmit}>
            Promote
          </Button>
        </Form>
      </div>
    );
  }
}

export default Promote;
