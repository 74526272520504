import React, { useState, useEffect } from "react";
import "firebase/auth";
import {Route,Navigate,useNavigate, Routes,Router} from 'react-router-dom';
import Login from "../login/component";
import State_verified from "./state_verified";
import State_unverified from "./state_unverified";
import State_incomp from "./state_incomplete";
import State_manage from "./state_manage";
import State_profile from "./state_profile";
import Verify from "./verify";
import S_District_unverified from "./s_d_uv";
import ReVerify from "./re_verify";
import Navbar from "../navbar";
import S_D_Verified from "./s_d_v";
import S_D_Assembly_verified from "./s_d_a_v";
import S_D_unVerified from "./s_d_uv";
import S_D_Assembly_unverified from "./s_d_a_uv";
import S_D_manage from "./s_d_m";
import S_D_Assembly_manage from "./s_d_a_m";
import S_uv_profile from "./s_uv_p";
import S_v_profile from "./s_v_p";
import Promote from "./promote";
import State_District_mem from './state_d_mem'
import State_mem from "./state_mem";
import S_D_A_mem from "./s_d_a_mem";

function Adm_state({auth_ad,det}){

    let history=useNavigate();
    // console.log(history);
    const pathname = window.location.pathname
    console.log(pathname)
    if(auth_ad==='state' && det){
        console.log(det)
        
        return <div style={{textAlign:"center"}}>
            <Navbar/>
            <h2>{det.full_name}</h2>
            <h3>{det.designation}, {det.mem_state}</h3>
            <Routes>
            <Route path='/members' element={<State_mem info={det}/>}/>
            <Route path='/members/:dis' element={<State_District_mem info={det}/>}/>
            <Route path='/members/:dis/:ass' element={<S_D_A_mem info={det} des={det.designation}/>}/>
            <Route path='/verified' element={<State_verified state={det.mem_state}/>}/>
            <Route path='/verified/:dis' element={<S_D_Verified state={det.mem_state}/>}/>
            <Route path='/verified/:dis/:ass' element={<S_D_Assembly_verified des={det.designation}/>}/>
            <Route path='/unverified/:dis/:ass' element={<S_D_Assembly_unverified des={det.designation}/>}/>
            <Route path='/unverified' element={<State_unverified state={det.mem_state}/>}/>
            <Route path='/incomplete' element={<State_incomp state={det.mem_state} des={det.designation}/>}/>
            <Route path='/manage' element={<State_manage state={det.mem_state} mob={det.mobile_no}/>}/>
            <Route path='/manage/:dis' element={<S_D_manage state={det.mem_state} mob={det.mobile_no}/>}/>
            <Route path='/manage/:dis/:ass' element={<S_D_Assembly_manage mob={det.mobile_no} des={det.designation}/>}/>
            <Route path='/profile' element={<State_profile person={det}/>}/>
            <Route path='/unverified/:dis/:ass/verify/:phone' element={<Verify mob={det.mobile_no}/>}/>
            <Route path='/verified/:dis/:ass/reverify/:phone' element={<ReVerify/>}/>
            <Route path='/members/:dis/:ass/verify/:phone' element={<Verify mob={det.mobile_no}/>}/>
            <Route path='/members/:dis/:ass/reverify/:phone' element={<ReVerify/>}/>
            <Route path='/unverified/:dis/:ass/profile/:phone' element={<S_uv_profile/>}/>
            <Route path='/members/:dis/:ass/profile/:phone' element={<S_uv_profile/>}/>
            <Route path='/incomplete/profile/:phone' element={<S_uv_profile/>}/>
            <Route path='/manage/:dis/:ass/profile/:phone' element={<S_uv_profile/>}/>
            <Route path='/verified/:dis/:ass/profile/:phone' element={<S_v_profile/>}/>
            <Route path='/verified/:dis/:ass/promote/:phone' element={<Promote mob={det.mobile_no}/>}/>
            <Route path='/members/:dis/:ass/profile/:phone' element={<S_v_profile/>}/>
            <Route path='/members/:dis/:ass/promote/:phone' element={<Promote mob={det.mobile_no}/>}/>
            <Route path='/unverified/:dis' element={<S_D_unVerified/>}/>
            </Routes>
        </div>
    }
    else{
        history('/login');
        return <Routes>
            <Route path='/login' element={<Login/>}/>
        </Routes>;
    }
}

export default Adm_state