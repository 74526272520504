import React from 'react'
import Tabs from '../Tabs/tabs';
import './tab.css'
import { Card, Form, Button } from "react-bootstrap";
import N_S_D_Assembly_verified from "./n_s_d_a_v";
import N_S_D_Assembly_unverified from "./n_s_d_a_uv";

function N_S_D_A_mem({info}) {
    console.log("this",info)
    // console.log(person.mem_consti)
  return (
    <div>
        <Tabs>
        <div label="Verified">
        <N_S_D_Assembly_verified info={info.mem_state} des={info.designation}/>
        </div>
        <div label="Unverified">
        <N_S_D_Assembly_unverified info={info.mem_state} des={info.designation}/>
        </div>
      </Tabs>
    </div>
  )
}

export default N_S_D_A_mem;