import React from "react";
import "firebase/auth";
import firebase from "../../Firebase";
import { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useNavigate } from "react-router-dom";
import Login from "../login/component";
import { Route, Routes, Link, useParams } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

const ref = firebase.firestore().collection("UserDetails");

console.log(ref);

function S_D_Verified() {
  let { dis } = useParams();
  let district = dis.replaceAll("_", " ");

  const dist_ref = firebase.database().ref("District");
  const [data, setData] = useState([]);
  let [cons, setCons] = useState([]);
  const [loader, setLoader] = useState(true);
  let con_list = [];
  function getData() {
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        if (
          doc.data().mem_district == district &&
          doc.data().is_verified == "Yes" &&
          doc.data().user_role == "member"
        ) {
          items.push(doc.data());
          con_list.push(doc.data().mem_consti);
          console.log(doc.data().mem_consti);
        }
      });
      setData(items);
      setCons(con_list);
      console.log(con_list);
      setLoader(false);
    });
  }

  console.log(cons);
  useEffect(() => {
    getData();
  }, []);

  return <div>{loader ? <F_load /> : <F_s_d_v get_c={cons} />}</div>;
  function F_load() {
    return <div>{/* hii */}</div>;
  }

  function F_s_d_v({ get_c }) {
    function removeDuplicates(arr) {
      return arr.filter((item, index) => arr.indexOf(item) === index);
    }

    const con_final = [];
    let counts={};
    get_c.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
    get_c = removeDuplicates(get_c);
    get_c.sort()
    console.log(get_c);
    let j=0;
    for (let i in get_c) {
      let con_det = (
        <div>
          <Link
            to={get_c[j].replace(/ /g, "_")}
            style={{ textDecoration: "none" }}
          >
            {get_c[i] + ' (' + counts[get_c[i]] +')'}
          </Link>
        </div>
      );
      j++;
      console.log(i, " ");
      let obj = { const_index: j , const_name: con_det };
      con_final.push(obj);
      }
      
      const const_columns = [
        {
          dataField: "const_index",
          text: "S.No",
          sort: true,
        },
        {
          dataField: "const_name",
          text: "Constituency",
          filter: textFilter(),
          sort: true,
        },
      ];

      return (
        <div>
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">
              Constituency List
            </h6>
          </div>
          <BootstrapTable
            pagination={paginationFactory()}
            keyField="const_index"
            data={con_final}
            columns={const_columns}
            filter={filterFactory()}
            striped
            hover
            condensed
          />
        </div>
      );
    
  }
}

export default S_D_Verified;
