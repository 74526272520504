import React from "react";
import "firebase/auth";
import firebase from "../../Firebase";
import { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useNavigate } from "react-router-dom";
import Login from "../login/component";
import { Route, Routes, Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

const ref = firebase.firestore().collection("UserDetails");
console.log(ref);

function State_manage({ state,mob }) {
  const [data, setData] = useState([]);
  let [dis, setDis] = useState([]);
  const [loader, setLoader] = useState(true);
  let dis_list = [];

  function getData() {
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        if (
          doc.data().mem_state == state &&
          doc.data().mobile_no !==mob  &&
          doc.data().user_role == "admin"
        ) {
          items.push(doc.data());
          dis_list.push(doc.data().mem_district);
          console.log(doc.data().mem_district);
        }
      });
      setData(items);
      setDis(dis_list);
      console.log(dis_list);
      setLoader(false);
    });
  }

  console.log(dis);
  useEffect(() => {
    getData();
  }, []);

  return <div>{loader ? <F_load /> : <F_s_d get_d={dis} />}</div>;

  function F_load() {
    return <div>{/* hii */}</div>;
  }

  function F_s_d({ get_d }) {
    function removeDuplicates(arr) {
      return arr.filter((item, index) => arr.indexOf(item) === index);
    }

    const dis_final=[]
    let counts={};
    get_d.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
    get_d=removeDuplicates(get_d)
    get_d.sort();
    let j=1;
    for(let i in get_d){
      let con_det=(<div>
        <Link to={get_d[i].replace(/ /g,"_")} style={{textDecoration:'none'}}>
        {get_d[i] + ' (' + counts[get_d[i]] +')'}
        </Link>
      </div>)
      console.log(i," ")
      let obj = { dist_index: j, dist_name: con_det };
      j++
      dis_final.push(obj);
    }

    const dist_columns = [
      {
        dataField: "dist_index",
        text: "S.No",
        sort: true,
      },
      {
        dataField: "dist_name",
        text: "District",
        sort:true,
        filter: textFilter(),
      },
    ];

    
    return (
      <div>
        <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">
          District list
        </h6>
      </div>
      <BootstrapTable
        pagination={paginationFactory()}
        keyField="const_index"
        data={dis_final}
        columns={dist_columns}
        filter={filterFactory()}
        striped
        hover
        condensed
      />
      </div>
    )
  }
}

export default State_manage;
