import React from 'react'
import Tabs from '../Tabs/tabs';
import './tab.css'
import { Card, Form, Button } from "react-bootstrap";
import Assembly_verified from './assembly_verified';
import Assembly_unverified from './assembly_unverified';

function Assembly_mem({info}) {
    console.log("this",info)
    // console.log(person.mem_consti)
  return (
    <div>
        <Tabs>
        <div label="Verified">
        <Assembly_verified assembly={info.mem_consti} des={info.designation}/>
        </div>
        <div label="Unverified">
        <Assembly_unverified assembly={info.mem_consti} des={info.designation}/>
        </div>
      </Tabs>
    </div>
  )
}

export default Assembly_mem;