import React from 'react'
import Tabs from '../Tabs/tabs';
import './tab.css'
import { Card, Form, Button } from "react-bootstrap";
import D_Assembly_verified from './d_assembly_v';
import D_Assembly_unverified from './d_assembly_uv';

function D_A_mem({info}) {
    console.log("this",info)
    // console.log(person.mem_consti)
  return (
    <div>
        <Tabs>
        <div label="Verified">
        <D_Assembly_verified info={info} des={info.designation}/>
        </div>
        <div label="Unverified">
        <D_Assembly_unverified info={info} des={info.designation}/>
        </div>
      </Tabs>
    </div>
  )
}

export default D_A_mem;