import React from "react";
import "firebase/auth";
import firebase from "../../Firebase";
import { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useNavigate } from "react-router-dom";
import Login from "../login/component";
import { Route, Routes, Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";



// console.log(ref);

function District_mem({ info }) {

    const [disList,setDisList]=useState();
    const [loader, setLoader] = useState(true);
    useEffect(()=>{
        const ref = firebase.database().ref('District');
        ref.on('value',(snapshot)=>{
            const disList=[];
            snapshot.forEach(function(childSnapshot){
                var a=childSnapshot.val();
                if(a.district_name==info.mem_district && info.mem_state==a.state_name){
                disList.push(a)
                }
            })
            setDisList(disList);   
            setLoader(false) 
            console.log("hihhi",disList)
        })
    },[])

    

    const const_columns = [
        {
          dataField: "const_index",
          text: "S.No",
          sort: true,
        },
        {
          dataField: "const_name",
          text: "Constituency",
          filter: textFilter(),
        },
      ];
//   console.log(info);

    return <div>{loader ? <F_load /> : <F_d_v get_c={disList} />}</div>;

    function F_load() {
        return <div>{/* hii */}</div>;
      }

      function F_d_v({get_c}){
          
        const cons_list=[];
          for(let i=0;i<get_c.length;i++){
            let con_det = (
                <div>
                  <Link
                    to={get_c[i].constituency_name.replace(/ /g, "_")}
                    style={{ textDecoration: "none" }}
                  >
                    {get_c[i].constituency_name}
                  </Link>
                </div>
              );
              cons_list.push({const_index:i+1,const_name:con_det})
          }
        //   console.log("hijdo")
        return (
            <div>
              <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">
                  District Verified Members
                </h6>
              </div>
              <BootstrapTable
                pagination={paginationFactory()}
                keyField="const_index"
                data={cons_list}
                columns={const_columns}
                filter={filterFactory()}
                striped
                hover
                condensed
              />
            </div>
          );
      }
}

export default District_mem;
