import React, { useState, useEffect } from "react";
import "firebase/auth";
import {Route,Navigate,useNavigate, Routes,Router} from 'react-router-dom';
import Login from "../login/component";
import Nation_verified from "./nation_verified";
import Nation_unverified from "./Nation_unverified";
import Nation_incomp from "./Nation_incomplete";
import Nation_manage from "./nation_manage";
import Nation_profile from "./nation_profile";
import Verify from "./verify";
import ReVerify from "./re_verify";
import Navbar from "../navbar";
import N_S_D_Verified from "./n_s_d_v";
import N_S_Verified from "./n_s_v";
import N_S_D_Assembly_verified from "./n_s_d_a_v";
import N_S_unVerified from "./n_s_uv";
import N_S_D_unVerified from "./n_s_d_uv";
import N_S_D_Assembly_unverified from "./n_s_d_a_uv";
import N_S_Manage from "./n_s_m";
import N_S_D_Assembly_manage from "./n_s_d_a_m";
import N_S_D_manage from "./n_s_d_m";
import N_v_profile from "./n_p_v";
import N_uv_profile from "./n_p_uv";
import Promote from "./promote";
import Nation_mem from "./nation_mem";
import N_S_mem from "./nation_s_mem";
import N_State_District_mem from "./n_s_d_mem";
import N_S_D_A_mem from "./n_s_d_a_mem";

function Adm_nation({auth_ad,det}){

    let history=useNavigate();
    // console.log(history);
    const pathname = window.location.pathname
    console.log(pathname)
    if(auth_ad==='national' && det){
        console.log(det)
        
        return <div style={{textAlign:"center"}}>
            <Navbar/>
            <h2>{det.full_name}</h2>
            <h3>{det.designation}, {det.country}</h3>
            <Routes>    
            <Route path='/verified' element={<Nation_verified country={det.country}/>}/>
            <Route path='/verified/:state' element={<N_S_Verified country={det.country}/>}/>
            <Route path='/verified/:state/:dis' element={<N_S_D_Verified country={det.country}/>}/>
            <Route path='/verified/:state/:dis/:ass' element={<N_S_D_Assembly_verified country={det.country} des={det.designation}/>}/>
            <Route path='/members' element={<Nation_mem info={det}/>}/>
            <Route path='/members/:state' element={<N_S_mem info={det}/>}/>
            <Route path='/members/:state/:dis' element={<N_State_District_mem info={det}/>}/>
            <Route path='/members/:state/:dis/:ass' element={<N_S_D_A_mem info={det}/>}/>
            <Route path='/unverified' element={<Nation_unverified country={det.country}/>}/>
            <Route path='/unverified/:state' element={<N_S_unVerified country={det.country}/>}/>
            <Route path='/unverified/:state/:dis' element={<N_S_D_unVerified country={det.country}/>}/>
            <Route path='/unverified/:state/:dis/:ass' element={<N_S_D_Assembly_unverified country={det.country} des={det.designation}/>}/>
            <Route path='/incomplete' element={<Nation_incomp country={det.country} des={det.designation}/>}/>
            <Route path='/incomplete/profile/:phone' element={<N_uv_profile/>}/>
            <Route path='/manage' element={<Nation_manage country={det.country} mob={det.mobile_no}/>}/>
            <Route path='/manage/:state' element={<N_S_Manage mob={det.mobile_no}/>}/>
            <Route path='/manage/:state/:dis' element={<N_S_D_manage mob={det.mobile_no}/>}/>
            <Route path='/manage/:state/:dis/:ass' element={<N_S_D_Assembly_manage mob={det.mobile_no} des={det.designation}/>}/>
            <Route path='/profile' element={<Nation_profile person={det}/>}/>
            <Route path='/unverified/:state/:dis/:ass/verify/:phone' element={<Verify mob={det.mobile_no}/>}/>
            <Route path='/verified/:state/:dis/:ass/reverify/:phone' element={<ReVerify/>}/>
            <Route path='/unverified/:state/:dis/:ass/profile/:phone' element={<N_uv_profile/>}/>
            <Route path='/verified/:state/:dis/:ass/profile/:phone' element={<N_v_profile/>}/>
            <Route path='/verified/:state/:dis/:ass/promote/:phone' element={<Promote mob={det.mobile_no}/>}/>
            <Route path='/members/:state/:dis/:ass/verify/:phone' element={<Verify mob={det.mobile_no}/>}/>
            <Route path='/members/:state/:dis/:ass/reverify/:phone' element={<ReVerify/>}/>
            <Route path='/members/:state/:dis/:ass/profile/:phone' element={<N_uv_profile/>}/>
            <Route path='/members/:state/:dis/:ass/profile/:phone' element={<N_v_profile/>}/>
            <Route path='/members/:state/:dis/:ass/promote/:phone' element={<Promote mob={det.mobile_no}/>}/>
            <Route path='/manage/:state/:dis/:ass/profile/:phone' element={<N_uv_profile/>}/>
            </Routes>
        </div>
    }
    else{
        history('/login');
        return <Routes>
            <Route path='/login' element={<Login/>}/>
        </Routes>;
    }
}

export default Adm_nation