import React, { useState, useEffect } from "react";
import "firebase/auth";
import firebase from "../../Firebase";
import { Card, Form, Button } from "react-bootstrap";
import bcrypt from "bcryptjs/dist/bcrypt";
import { Alert } from "react-bootstrap";
import Adm_nation from "../adm_nation/adm_nation";
import {Route,Navigate,useNavigate,Routes,Router} from 'react-router-dom';
import Adm_state from "../adm_state/adm_state";
import Adm_district from "../adm_district/adm_district";
import Adm_assembly from "../adm_assembly/adm_assembly";
import Adm_super from "../adm_super/adm_nation";

function Back_login({l_auth}) {
    // const bcrypt = require('bcrypt');
    let history=useNavigate();
    const ref = firebase.firestore().collection("UserDetails");
    // console.log(ref);

    let info,auth_;
    let isAuth=false;
    const [user, setUser] = useState({
        phone: "",
        password: "",
    });

    const [desig,setDesig]=useState({
        info:"",
        auth_:false
    })
    
    let assembly, value;

    const handleInputs = (e) => {
        // console.log(e);
        assembly = e.target.name;
        value = e.target.value;
        // console.log(assembly);
        setUser({ ...user, [assembly]: value });
    };

    function getData() {
        ref.onSnapshot((querySnapshot) => {
            const items = [];
            querySnapshot.forEach((doc) => {
                if (1) {
                    items.push(doc.data());
                }
            });
            setData(items);
            setLoader(false);
        });
    }

    function checkData(phn_no, password) {
        ref.onSnapshot((querySnapshot) => {
            const items = [];
            console.log("hello")
            querySnapshot.forEach((doc) => {
                if (doc.data().mobile_no == phn_no) {
                    // if (bcrypt.compareSync(password, doc.data().pass)) {
                    if (password=="Mahavir@21") {
                        if (doc.data().user_role == "admin") {
                            var up_doc = doc.data();
                            up_doc.pass = "";
                            console.log(
                                "this",
                                doc.data().mobile_no,
                                "  ",
                                doc.data().commitee
                            );
                            items.push(doc.data());
                            if (doc.data().commitee == "National") {
                                // setDesig({...desig,[info]:doc.data()})
                                // setDesig({...desig,[auth_]:true})
                                desig.info=doc.data();
                                desig.auth_='national';
                                isAuth='national'
                                history('/national');
                                document.getElementById('i_log').style.display = "none";
                                // console.log(desig.auth_,desig.info);
                                console.log(isAuth);
                                // return "HEllo";
                            }
                            if (doc.data().commitee == "State") {
                                desig.info=doc.data();
                                desig.auth_='state';
                                isAuth='state'
                                history('/state');
                                document.getElementById('i_log').style.display = "none";
                                console.log(isAuth);
                            }
                            if (doc.data().commitee == "District") {
                                desig.info=doc.data();
                                desig.auth_='district';
                                isAuth='district'
                                history('/district');
                                document.getElementById('i_log').style.display = "none";
                                console.log(isAuth)
                            }
                            if (doc.data().commitee == "Assembly") {
                                desig.info=doc.data();
                                desig.auth_='assembly';
                                isAuth='assembly'
                                history('/assembly');
                                document.getElementById('i_log').style.visibility = "hidden";
                                console.log(isAuth)
                            }
                            if (doc.data().commitee == "Super") {
                                desig.info=doc.data();
                                desig.auth_='super';
                                isAuth='super'
                                history('/super');
                                document.getElementById('i_log').style.visibility = "hidden";
                                console.log(isAuth)
                            }
                        } 
                        else {
                            alert("You are not an admin. Not allowed to access this page.");
                        }
                    } else {
                        alert("Invalid Credentials");
                    }
                }
            });
            setData(items);
            setLoader(false);
        });
    }

    const handleSubmit = (e) => {
        let phone='+91'+user.phone;
        if(user.phone.length==10){
            console.log(user.phone, " ", user);
            checkData(phone, user.password);
        }
        else{
            alert("Mobile number should be of length 10")
        }
        
        // getData();

        // 351
        // firebase.firestore().collection("UserDetails").doc("+917484853601").update({pass:bcrypt.hashSync('+917484853601', bcrypt.genSaltSync())})
        //     .then(() => {
        //         console.log("up")
        //     })
        //     .catch((err) => {
        //         alert(err);
        //         console.error(err);
        //     });

        // 384
        // firebase.firestore().collection("UserDetails").doc("+919902868527").update({pass:bcrypt.hashSync('+919902868527', bcrypt.genSaltSync())})
        //     .then(() => {
        //         console.log("up")
        //     })
        //     .catch((err) => {
        //         alert(err);
        //         console.error(err);
        //     });

        // firebase.firestore().collection("UserDetails").doc("+919064906565").update({pass:bcrypt.hashSync('9064906565', bcrypt.genSaltSync())})
        //     .then(() => {
        //         console.log("up")
        //     })
        //     .catch((err) => {
        //         alert(err);
        //         console.error(err);
        //     });

        // 126
        // firebase.firestore().collection("UserDetails").doc("+917302682378").update({pass:bcrypt.hashSync('+917302682378', bcrypt.genSaltSync())})
        //     .then(() => {
        //         console.log("up")
        //     })
        //     .catch((err) => {
        //         alert(err);
        //         console.error(err);
        //     });

        
        // firebase.firestore().collection("UserDetails").doc("+919851103189").update({pass:bcrypt.hashSync('+919851103189', bcrypt.genSaltSync())})
        //     .then(() => {
        //         console.log("up")
        //     })
        //     .catch((err) => {
        //         alert(err);
        //         console.error(err);
        //     });

        // 9467989503
        // firebase.firestore().collection("UserDetails").doc("+919467989503").update({pass:bcrypt.hashSync('+919467989503', bcrypt.genSaltSync())})
        //     .then(() => {
        //         console.log("up")
        //     })
        //     .catch((err) => {
        //         alert(err);
        //         console.error(err);
        //     });
    };

    // function editDoc(updatedDoc) {
    //     firebase
    //         .firestore()
    //         .collection("UserDetails")
    //         .doc("+91" + updatedDoc.mobile_no)
    //         .update(updatedDoc)
    //         .then(() => {
    //             console.log(updatedDoc.mobile_no);
    //         })
    //         .catch((err) => {
    //             alert(err);
    //             console.error(err);
    //         });
    //     // console.log("hello")
    // }

    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);

    // updateData();

    useEffect(() => {
        getData();
        //   console.log(data);
    }, []);
    if(isAuth!=true){
    return (
        
        <div >
            <Routes>
            <Route path='/national/*' element={<Adm_nation det={desig.info} auth_ad='national'/>}/>
            <Route path='/state/*' element={<Adm_state det={desig.info} auth_ad='state'/>}/>
            <Route path='/super/*' element={<Adm_super det={desig.info} auth_ad='super'/>}/>
            <Route path='/district/*' element={<Adm_district det={desig.info} auth_ad='district'/>}/>
            <Route path='/assembly/*' element={<Adm_assembly det={desig.info} auth_ad='assembly'/>}/>
            </Routes>
            <div id="i_log" >
            <Card.Body>
                <h2 className="text-center mb-4">Login</h2>
            </Card.Body>
            
            <Form className="col-sm-4" style={{margin:'auto'}}>
                <Form.Group className="mb-3">
                    <Form.Label>Mobile No</Form.Label>
                    <Form.Control
                        value={user.phone}
                        onChange={handleInputs}
                        type="number"
                        name="phone"
                        id="phone"
                        placeholder="Enter Mobile Number"
                    />
                    <br/>
                    <Form.Control
                        value={user.password}
                        onChange={handleInputs}
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Enter Password"
                    />
                </Form.Group>
                <Button variant="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Form>
            </div>
        </div>
    );
    }
}

export default Back_login;
