import React from 'react'
import { useHistory,Navigate, useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from "react";
import "firebase/auth";
import firebase from "../../Firebase";
import { Card, Form, Button } from "react-bootstrap";



// let data=[];

function ReVerify() {

  
const ref = firebase.firestore().collection("UserDetails");
  // let location = useLocation();
  // let v=location.state.district;

// const [ loading, setLoading ] = useState(false);

let location=useLocation();
console.log("jii",location)

const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);


function getData() {
  ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
          if (doc.data().mobile_no==phone) {
              items.push(doc.data());
          }
      });
      setData(items);
      setLoader(false);
  });
}



// const storageRef=firebase.storage().ref;
var storage = firebase.storage();
let assembly, value,voter_slip_url;



  let {phone}=useParams();
  useEffect(() => {
    getData();
    console.log(data);
    console.log("Assembly");
  }, []);

  console.log(phone,data,loader);

  return (
    <div>
      {loader ? <F_load/> : <F_ver mem={data[0]}/>}
    </div>

  )

  function F_load(){
    return <div>
      {/* hii */}
    </div>
  }

  function F_ver({mem}){

    const [user, setUser] = useState({
      add: mem.mem_add,
      booth: mem.booth_no,
      file:""
  });
  
    console.log("mem",mem);
    const handleInputs = (e) => {
      assembly = e.target.name;
      value = e.target.value;
      setUser({ ...user, [assembly]: value });
    };

    
  const onFileChange=(e)=>{
    setUser({ ...user, ['file']: e.target.files[0] });
  }

  const handleSubmit = (e) => {
    console.log("hiii",user.file)
    let file=user.file;
    // const uploadFiles=()=>{
      if(!file){
        // return;
        // alert("Please upload Voter slip")
        firebase.firestore().collection("UserDetails").doc(phone).update({is_verified:"Yes",booth_no:user.booth,mem_add:user.add})
              .then(() => {
                  console.log("up")
                  alert("User Verified")
              })
              .catch((err) => {
                  alert(err);
                  console.error(err);
              });
      }
      else{
        storage.ref(`/VoterSlip/${phone}`).put(file).on("state_changed",(err)=>console.log(err));
        storage.ref(`/VoterSlip/${phone}`).getDownloadURL().then(function(url){
          voter_slip_url=url;
          console.log(voter_slip_url);
    firebase.firestore().collection("UserDetails").doc(phone).update({voter_slip:voter_slip_url,is_verified:"Yes",booth_no:user.booth,mem_add:user.add})
              .then(() => {
                  console.log("up")
                  alert("User Verified")
              })
              .catch((err) => {
                  alert(err);
                  console.error(err);
              });
        })
      }
    // }
    console.log("voter_slip_url",voter_slip_url)
    e.preventDefault();
  }
  
    
    return <div>
      <Form>
            {/* {data[0].full_name + data[0].mobile_no} */}
              <Form.Group>
              <Form.Label><b>Member Name and Mobile No</b></Form.Label>
                    <Form.Control
                        value={data[0].full_name +' '+ data[0].mobile_no}
                        type="text"
                        id="name"
                        name="name"
                        placeholder={data[0].full_name +' '+ data[0].mobile_no}
                        disabled
                    />
                    <br/>
                    <Form.Label><b>Voter Id</b></Form.Label>
                    <Form.Control
                        value={data[0].voter_id}
                        type="text"
                        id="voter_id"
                        name="voter_id"
                        placeholder={data[0].voter_id}
                        disabled
                    />
                    <br/>
                    <Form.Label><b>Voter Slip</b></Form.Label>
                    <Form.Control
                    type="file"
                    onChange={onFileChange}
                    // required
                    />
                    <br/>
                    <Form.Label><b>Address</b></Form.Label>
                    <Form.Control
                    value={user.add}
                        onChange={handleInputs}
                        type="text"
                        defaultValue={data[0].mem_add}
                        name="add"
                        id="add"
                        // placeholder={data[0].mem_add}
                    />
                    <br/>
                    <Form.Label><b>Part/Booth No.</b></Form.Label>
                    <Form.Control
                    value={user.booth}
                        onChange={handleInputs}
                        defaultValue={data[0].booth_no}
                        type="text"
                        name="booth"
                        id="booth"
                        // placeholder={data[0].booth_no}
                    />
              </Form.Group>
              <Button variant="success" onClick={handleSubmit}>
                    Re-Verify
                </Button>
            </Form>
    </div>
  }
}

export default ReVerify