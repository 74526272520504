import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import 'firebase/storage'
import firebase from 'firebase/app';
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: "AIzaSyBXvTxEhrPW_PMKCHreNF7gkTYRXoE-_tE",
    authDomain: "userandroidapp-35966.firebaseapp.com",
    databaseURL: "https://userandroidapp-35966-default-rtdb.firebaseio.com",
    projectId: "userandroidapp-35966",
    storageBucket: "userandroidapp-35966.appspot.com",
    messagingSenderId: "253073181968",
    appId: "1:253073181968:web:a1bd37b9a87f8532c2e995",
    measurementId: "G-B45MSQPK6S"
  };

firebase.initializeApp(firebaseConfig);

export const auth=firebase.auth(); 
// const storageRef=firebase.storage().ref;
export default firebase