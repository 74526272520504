import React from "react";
import "firebase/auth";
import firebase from "../../Firebase";
import { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useNavigate } from "react-router-dom";
import Login from "../login/component";
import { Route, Routes,Link, useParams } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

const ref = firebase.firestore().collection("UserDetails");

console.log(ref);

function N_S_D_Assembly_manage({des}){
    let {ass}=useParams();
    let assembly=ass.replaceAll('_', ' ');
    const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);

  function getData() {
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        if (
          doc.data().mem_consti == assembly &&
          doc.data().is_verified == "Yes" &&
          doc.data().user_role == "admin"
        ) {
          items.push(doc.data());
        }
      });
      setData(items);
      setLoader(false);
    });
  }

  useEffect(() => {
    getData();
    console.log(data);
    console.log("Assembly");
  }, []);

  function block(mem_id) {
    console.log("block", mem_id);

    firebase.firestore().collection("UserDetails").doc(mem_id).update({is_active:"No"})
            .then(() => {
                console.log("up")
                alert("User Blocked");
            })
            .catch((err) => {
                alert(err);
                console.error(err);
            });
  }

  function unblock(mem_id) {
    console.log("block", mem_id);

    firebase.firestore().collection("UserDetails").doc(mem_id).update({is_active:"Yes"})
            .then(() => {
                console.log("up")
                alert("User Unblocked");
            })
            .catch((err) => {
                alert(err);
                console.error(err);
            });
  }


  function demote(mem_id) {
    // console.log("block", mem_id);

    firebase
      .firestore()
      .collection("UserDetails")
      .doc(mem_id)
      .update({ user_role: "member" ,designation:"",commitee:""})
      .then(() => {
        // console.log("up");
        alert("User Demoted");
      })
      .catch((err) => {
        alert(err);
        // console.error(err);
      });
  }

  let mem = [];
  for (let i = 0; i < data.length; i++) {
    let btn ="";
    if(data[i].is_active=="Yes"){btn = (
      <div>
        <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Action
              </Dropdown.Toggle>
              <Dropdown.Menu>
              <Dropdown.Item><Link to={'profile/'+data[i].mobile_no} style={{textDecoration:'none'}}>
                  Profile
                  </Link></Dropdown.Item>
                  <Dropdown.Item>
                <div onClick={() => demote(data[i].mobile_no)}>Demote</div>
              </Dropdown.Item>
              <Dropdown.Item><div onClick={()=>block(data[i].mobile_no)}>
                    Block
                  </div></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
      </div>
    );}
    else{
      btn = (
        <div>
          <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Action
                </Dropdown.Toggle>
                <Dropdown.Menu>
                <Dropdown.Item><Link to={'profile/'+data[i].mobile_no} style={{textDecoration:'none'}}>
                    Profile
                    </Link></Dropdown.Item>
                <Dropdown.Item><div onClick={()=>unblock(data[i].mobile_no)}>
                      Unblock
                    </div></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
        </div>
      );
    }
    const reverseDateRepresentation = date => {
      let parts = date.split('-');
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    };
    let obj = {
      mem_mobile: data[i].full_name + "\n" + data[i].mobile_no + "\n" + data[i].designation,
      mem_add: "Part No. - p" + data[i].booth_no,
      mem_date:data[i].join_date,
      mem_t_date:reverseDateRepresentation(data[i].join_date),
      mem_action: btn,
    };
    mem.push(obj);
  }
  console.log(mem);
  mem.sort((a, b) => {
    return new Date(b.mem_t_date) - new Date(a.mem_t_date); // descending
  })

  let y=""

  if(des=="National President"){
y=[
  {
    dataField: "mem_mobile",
    text: "Name",
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: "mem_add",
    text: "Address",
    filter: textFilter(),
  },
  {
    dataField: "mem_date",
    text: "Join Date",
    filter: textFilter(),
  },
  {
    dataField: "mem_action",
    text: "Action",
    // filter:textFilter()
  },
];
  }
  else{
    y=[
      {
        dataField: "mem_mobile",
        text: "Name",
        sort: true,
        filter: textFilter(),
      },
      {
        dataField: "mem_add",
        text: "Address",
        filter: textFilter(),
      },
      {
        dataField: "mem_date",
        text: "Join Date",
        filter: textFilter(),
      }
    ];
  }
  const mem_columns = y


    return <div>
        <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">
          {assembly} Management
        </h6>
      </div>
      <div class="table-responsive">
        <BootstrapTable
          pagination={paginationFactory()}
          keyField="id"
          data={mem}
          columns={mem_columns}
          filter={filterFactory()}
          striped
          hover
          condensed
        />
      </div>
    </div>
}

export default N_S_D_Assembly_manage