import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';

import * as AiIcons from 'react-icons/ai';
import { Link,useNavigate } from 'react-router-dom';
import { SidebarData } from './sidebar';
import './navbar.css';
import { IconContext } from 'react-icons';

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  let history=useNavigate();
  const showSidebar = () => setSidebar(!sidebar);

  function log_out(){
    window.location.reload(false);
  }

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='navbar'>
          <Link to='#' className='menu-bars'>
            <FaIcons.FaBars onClick={showSidebar} />
          </Link>
        </div>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={showSidebar}>
            <li className='navbar-toggle'>
              <Link to='#' className='menu-bars'>
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {SidebarData.map((item, index) => {
              let pathname = window.location.pathname
              let fi_word=pathname.split('/')[0]+pathname.split('/')[1];
              console.log("hhh",fi_word+item.path)
              let a='/'+fi_word+item.path
              return (
                <li key={index} className={item.cName}>
                  <Link exact to={a}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
            <li onClick={log_out} className='nav-text'>
              <Link exact to=''>
              <FaIcons.FaLock />
                <span>Log Out</span></Link></li>
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;