import React from "react";
import "firebase/auth";
import firebase from "../../Firebase";
import { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useNavigate } from "react-router-dom";
import Login from "../login/component";
import { Route, Routes, Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

const ref = firebase.firestore().collection("UserDetails");

console.log(ref);

function District_verified({ district,des }) {
  const dist_ref = firebase.database().ref("District");
  const [data, setData] = useState([]);
  let [cons, setCons] = useState([]);
  const [loader, setLoader] = useState(true);
  let con_list = [];
  function getData() {
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        if (
          doc.data().mem_district == district &&
          doc.data().is_verified == "Yes" &&
          doc.data().user_role == "member"
        ) {
          items.push(doc.data());
          con_list.push(doc.data().mem_consti);
          console.log(doc.data().mem_consti);
        }
      });
      setData(items);
      setCons(con_list);
      console.log(con_list);
      setLoader(false);
    });
  }

  console.log(cons);
  useEffect(() => {
    getData();
  }, []);

  function block(mem_id) {
    console.log("block", mem_id);

    firebase
      .firestore()
      .collection("UserDetails")
      .doc(mem_id)
      .update({ is_active: "No" })
      .then(() => {
        console.log("up");
        alert("User Blocked");
      })
      .catch((err) => {
        alert(err);
        console.error(err);
      });
  }

  function get_member(const_n) {
    let mem = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].mem_consti == const_n) {
        let btn = (
          <div>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Action
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>Promote</Dropdown.Item>
                <Dropdown.Item>
                  <Link
                    to={"reverify/" + data[i].mobile_no}
                    style={{ textDecoration: "none" }}
                  >
                    Re-Verify
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item><Link to={'profile/'+data[i].mobile_no} style={{textDecoration:'none'}}>
                  Profile
                  </Link></Dropdown.Item>
                <Dropdown.Item>
                  <div onClick={() => block(data[i].mobile_no)}>Block</div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
        let obj = {
          mem_mobile: data[i].full_name + "\n" + data[i].mobile_no,
          mem_add: "Part No. - p" + data[i].booth_no,
          mem_action: btn,
        };
        mem.push(obj);
      }
    }
    console.log(mem);
    return mem;
  }

  const expandConstRow = {
    renderer: (row) => (
      <BootstrapTable
        // pagination={ paginationFactory() }
        keyField="id"
        data={get_member(row.const_name)}
        columns={mem_columns}
        filter={filterFactory()}
        striped
        hover
        condensed
      />
    ),
  };

  const const_columns = [
    {
      dataField: "const_index",
      text: "S.No",
      sort: true,
    },
    {
      dataField: "const_name",
      text: "Constituency",
      filter: textFilter(),
    },
  ];

  let y=""

  if(des=="District President"){
    y=[
      {
        dataField: "mem_mobile",
        text: "Name",
        sort: true,
        filter: textFilter(),
      },
      {
        dataField: "mem_add",
        text: "Address",
        filter: textFilter(),
      },
      {
        dataField: "mem_action",
        text: "Action",
        // filter:textFilter()
      },
    ];
  }
  else{
    y=[
      {
        dataField: "mem_mobile",
        text: "Name",
        sort: true,
        filter: textFilter(),
      },
      {
        dataField: "mem_add",
        text: "Address",
        filter: textFilter(),
      },
    ];
  }

  const mem_columns = y

  return <div>{loader ? <F_load /> : <F_d_v get_c={cons} />}</div>;

  function F_load() {
    return <div>{/* hii */}</div>;
  }

  function F_d_v({ get_c }) {
    function removeDuplicates(arr) {
      return arr.filter((item, index) => arr.indexOf(item) === index);
    }

    const con_final = [];
    let counts={};
    get_c.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
    get_c = removeDuplicates(get_c);
    console.log(get_c);
    get_c.sort()
    for (let i in get_c) {
      let con_det = (
        <div>
          <Link
            to={get_c[i].replace(/ /g, "_")}
            style={{ textDecoration: "none" }}
          >
            {get_c[i] + ' (' + counts[get_c[i]] +')'}
          </Link>
        </div>
      );
      console.log(i, " ");
      let obj = { const_index: i + 1, const_name: con_det };
      con_final.push(obj);
    }
    return (
      <div>
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">
            District Verified Members
          </h6>
        </div>
        <BootstrapTable
          pagination={paginationFactory()}
          keyField="const_index"
          data={con_final}
          columns={const_columns}
          filter={filterFactory()}
          striped
          hover
          condensed
        />
      </div>
    );
  }
}

export default District_verified;
