import React, { useState, useEffect } from "react";
import "firebase/auth";
import {Route,Navigate,useNavigate, Routes,Router} from 'react-router-dom';
import Login from "../login/component";
import District_verified from "./district_verified";
import District_unverified from "./district_unverified";
import District_incomp from "./district_incomplete";
import District_manage from "./district_manage";
import District_profile from "./district_profile"
import Verify from "./verify";
import ReVerify from "./re_verify";
import Navbar from "../navbar";
import D_Assembly_verified from "./d_assembly_v";
import D_Assembly_unverified from "./d_assembly_uv";
import D_Assembly_manage from "./d_assembly_m";
import D_uv_profile from "./d_p_uv";
import D_v_profile from "./d_p_v";
import Promote from "./promote";
import District_mem from "./district_mem";
import D_A_mem from "./district_assembly_mem";

function Adm_district({auth_ad,det}){

    let history=useNavigate();
    // console.log(history);
    const pathname = window.location.pathname
    console.log(pathname)
    if(auth_ad==='district' && det){
        console.log(det)
        
        return <div style={{textAlign:"center"}}>
            <Navbar/>
            <h2>{det.full_name}</h2>
            <h3>{det.designation}, {det.mem_district}</h3>
            <Routes>
            <Route path='/verified' element={<District_verified district={det.mem_district}/>}/>
            <Route path='/members' element={<District_mem info={det}/>}/>
            <Route path='/verified/:ass' element={<D_Assembly_verified des={det.designation}/>}/>
            <Route path='/members/:ass' element={<D_A_mem info={det}/>}/>
            <Route path='/members/:ass/reverify/:phone' element={<ReVerify/>}/>
            <Route path='/members/:ass/verify/:phone' element={<Verify/>}/>
            <Route path='/members/:ass/profile/:phone' element={<D_v_profile/>}/>
            <Route path='/members/:ass/promote/:phone' element={<Promote mob={det.mobile_no}/>}/>
            <Route path='/unverified' element={<District_unverified district={det.mem_district}/>}/>
            <Route path='/unverified/:ass' element={<D_Assembly_unverified district={det.mem_district} des={det.designation}/>}/>
            <Route path='/incomplete' element={<District_incomp district={det.mem_district} des={det.designation}/>}/>
            <Route path='/incomplete/profile/:phone' element={<D_uv_profile/>}/>
            <Route path='/manage' element={<District_manage district={det.mem_district} mob={det.mobile_no}/>}/>
            <Route path='/manage/:ass' element={<D_Assembly_manage mob={det.mobile_no} des={det.designation}/>}/>
            <Route path='/profile' element={<District_profile person={det}/>}/>
            <Route path='/unverified/:ass/verify/:phone' element={<Verify mob={det.mobile_no}/>}/>
            <Route path='/verified/:ass/reverify/:phone' element={<ReVerify/>}/>
            <Route path='/unverified/:ass/profile/:phone' element={<D_uv_profile/>}/>
            <Route path='/manage/:ass/profile/:phone' element={<D_uv_profile/>}/>
            <Route path='/verified/:ass/profile/:phone' element={<D_v_profile/>}/>
            <Route path='/verified/:ass/promote/:phone' element={<Promote mob={det.mobile_no}/>}/>
            </Routes>
        </div>
    }
    else{
        history('/login');
        return <Routes>
            <Route path='/login' element={<Login/>}/>
        </Routes>;
    }
}

export default Adm_district