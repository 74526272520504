import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';

// const pathname = window.location.pathname;

export const SidebarData = [
  // {
  //   title: 'Posts',
  //   path: '/',
  //   // icon: <AiIcons.AiFillHome />,
  //   cName: 'nav-text'
  // },
  // {
  //   title: 'Management news',
  //   path: '/',
  //   // icon: <AiIcons.AiFillHome />,
  //   cName: 'nav-text'
  // },
  {
    title: 'Management',
    path: '/manage',
    icon: <FaIcons.FaUsers />,
    cName: 'nav-text'
  },

  {
    title: 'Members',
    path: '/members',
    icon: <FaIcons.FaUsers />,
    cName: 'nav-text'
  },

  // {
  //   title: 'Verified Members',
  //   path: '/verified',
  //   icon: <FaIcons.FaUsers />,
  //   cName: 'nav-text'
  // },
  // {
  //   title: 'UnVerified',
  //   path: '/unverified',
  //   icon:<FaIcons.FaUsers />,
  //   cName: 'nav-text'
  // },
  {
    title: 'Incomplete Profiles',
    path: '/incomplete',
    icon: <FaIcons.FaUsers />,
    cName: 'nav-text'
  },
  {
    title: 'Add Member',
    path: '/support',
    icon: <FaIcons.FaUser />,
    cName: 'nav-text'
  },
  // {
  //   title: 'Action Logs',
  //   path: '/support',
    // icon: <IoIcons.IoMdHelpCircle />,
  //   cName: 'nav-text'
  // },
  {
    title: 'My Profile',
    path: '/profile',
    icon: <FaIcons.FaEdit />,
    cName: 'nav-text'
  },
  // {
  //   title: 'Log Out',
  //   path: '/log_out',
  //   // icon: <IoIcons.IoMdHelpCircle />,
  //   cName: 'nav-text'
  // }
];

export default SidebarData