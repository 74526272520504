import logo from '../logo.svg';
import "firebase/auth"
import firebase from '../Firebase';
import '../App.css';
// import Signup from './Signup';
import Login from './login/component';
import Back_login from './backdoor_login/component';
import React from 'react';
import Navbar from './navbar';
import { BrowserRouter as Router,Routes, Route,Link } from 'react-router-dom';
// import Home from './home';
// import Reports from './assembly/unverified_mem';
// import Products from './assembly/verified_mem';
// import District_Unverified from './district/unverified_mem';
// import State_Unverified from './state/unverified_mem';
// import State_Verified from './state/verified_mem';
import District_Unverified from './district/unverified_mem';

// imp:::  $2y$10$r3AlUssGyi5V9LHJBUXYG.y9PY8PwFzPnpo42lMln1Y1ewyUw4yB2
function App() {
  return (
    <div>
      <Router>
         {/* <Navbar /> */}
         <Routes>
           <Route path='/*' element={<Login/>} />
           <Route path='/login' element={<Login/>} />
           <Route path='/back_login' element={<Back_login/>} />
           </Routes>
       </Router>
    </div>
  );
}

export default App;