import React from "react";
import "firebase/auth";
import firebase from "../../Firebase";
import { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useNavigate } from "react-router-dom";
import Login from "../login/component";
import { Route, Routes,Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

const ref = firebase.firestore().collection("UserDetails");
console.log(ref);

function Nation_verified({ country }) {
  let history = useNavigate();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  let [state__, setState__] = useState([]);
  let state_list=[];
  function getData() {
    ref.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        if (
          // doc.data().country == country &&
          doc.data().is_verified == "Yes" &&
          doc.data().user_role == "member"
        ) {
          items.push(doc.data());
          console.log(doc.data().mem_state)
          state_list.push(doc.data().mem_state)
        }
      });
      setData(items);
      setState__(state_list);
      setLoader(false);
    });
  }

  useEffect(() => {
    getData();
    // console.log(data);
    console.log("country");
  }, []);

  return <div>{loader ? <F_load /> : <F_s get_s={state__} />}</div>;

  function F_load() {
    return <div>{/* hii */}</div>;
  }

  function removeDuplicates(arr) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  function F_s({get_s}){
    
    const state_final=[];
    let counts={};
    get_s.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
    get_s=removeDuplicates(get_s)
    get_s.sort()
    let j=1;
    for(let i in get_s){
      let con_det=(<div>
        <Link to={get_s[i].replace(/ /g,"_")} style={{textDecoration:'none'}}>
        {get_s[i] + ' (' + counts[get_s[i]] +')'}
        </Link>
      </div>)
      console.log(i," ")
      let obj = { dist_index: j, dist_name: con_det };
      j++
      state_final.push(obj);
    }
    

    const state_columns = [
      {
        dataField: "dist_index",
        text: "S.No",
        sort: true,
      },
      {
        dataField: "dist_name",
        text: "State",
        sort:true,
        filter: textFilter(),
      },
    ];

    return (
      <div>
        <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">
          State list
        </h6>
      </div>
      <BootstrapTable
        pagination={paginationFactory()}
        keyField="const_index"
        data={state_final}
        columns={state_columns}
        filter={filterFactory()}
        striped
        hover
        condensed
      />
      </div>
    )
  }
}

export default Nation_verified;
