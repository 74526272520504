import React,{useState} from "react";
import "firebase/auth"
import firebase from '../../Firebase';

const ref=firebase.firestore().collection("UserDetails");
console.log(ref);


function UnVerified({ dev,assemblyf}) {
    

    const [editbox,seteditbox]=useState(false);


    function DeleteDoc(docx){
        ref.doc(docx.id)
        .delete()
        .catch((err)=>{
            alert(err)
            console.log(err);
        })
    }

    // console.log("helloo");
    // console.log(assembly);

  if (1) {
    //   console.log(assemblyf)
    return (
    //   <div key={dev.memdet_uid}>
    //     <h1>{dev.mem_uid}</h1>
    //   </div>
    <tbody>
        <tr>
        <td>{dev.mem_uid}</td>
        <td>{dev.full_name}</td>
        <td>{dev.mem_state}</td>
        <td>{dev.mem_district}</td>
        </tr>
        </tbody>
    );
  }
  return "";
}

export default UnVerified;
