import React, { useState, useEffect } from "react";
import "firebase/auth";
import {Route,Navigate,useNavigate, Routes,Router} from 'react-router-dom';
import Login from "../login/component";
import Assembly_verified from "./assembly_verified";
import Assembly_unverified from "./assembly_unverified";
import Assembly_incomp from "./assembly_incomplete";
import Assembly_manage from "./assembly_manage";
import Assembly_profile from "./assembly_profile";
import Assembly_mem from "./assembly_mem";
import Verify from "./verify";
import ReVerify from "./re_verify";
import Navbar from "../navbar";
import Assembly_v_profile from "./a_v_p";
import Assembly_uv_profile from "./a_uv_p";
import Promote from "./promote";
// import ToolkitProvider from 'react-bootstrap-table2-toolkit';
// Shree Ram Sena Sangathan
// 
function Adm_assembly({auth_ad,det}){

    let history=useNavigate();
    // console.log(history);
    const pathname = window.location.pathname
    console.log(pathname)
    if(auth_ad==='assembly' && det){
        console.log(det)
        return <div style={{textAlign:"center"}}>
            
         <Navbar />
            <h2>{det.full_name}</h2>
            <h3>{det.designation}, {det.mem_consti}</h3>
            <Routes>
            <Route path='/verified' element={<Assembly_verified assembly={det.mem_consti} des={det.designation}/>}/>
            <Route path='/unverified' element={<Assembly_unverified assembly={det.mem_consti} des={det.designation}/>}/>
            <Route path='/members' element={<Assembly_mem info={det}/>}/>
            <Route path='/members/profile/:phone' element={<Assembly_v_profile/>}/>
            <Route path='/members/promote/:phone' element={<Promote mob={det.mobile_no} per={det}/>}/>
            <Route path='/members:phone' element={<ReVerify/>}/>
            <Route path='/incomplete' element={<Assembly_incomp assembly={det.mem_consti} des={det.designation}/>}/>
            <Route path='/incomplete/profile/:phone' element={<Assembly_uv_profile/>}/>
            <Route path='/manage' element={<Assembly_manage assembly={det.mem_consti} mob={det.mobile_no}  des={det.designation}/>}/>
            <Route path='/manage/profile/:phone' element={<Assembly_uv_profile/>}/>
            <Route path='/profile' element={<Assembly_profile person={det}/>}/>
            <Route path='/unverified/verify/:phone' element={<Verify mob={det.mobile_no}/>}/>
            <Route path='/unverified/profile/:phone' element={<Assembly_uv_profile/>}/>
            <Route path='/verified/reverify/:phone' element={<ReVerify/>}/>
            <Route path='/verified/profile/:phone' element={<Assembly_v_profile/>}/>
            <Route path='/verified/promote/:phone' element={<Promote mob={det.mobile_no} per={det}/>}/>
            </Routes>
        </div>
    }
    else{
        history('/login');
        return <Routes>
            <Route path='/login' element={<Login/>}/>
        </Routes>;
    }
}

export default Adm_assembly